<template>
  <content-loader
    :height="230"
    :width="230"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="0" y="0" rx="3" ry="3" width="230" height="160"/>
    <rect x="10" y="175" rx="3" ry="3" width="140" height="10"/>
    <rect x="10" y="200" rx="3" ry="3" width="75" height="10"/>
    <circle cx="200" cy="190" r="15"/>
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
